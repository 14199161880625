import { gql } from '@apollo/client';

import { Charges } from 'charges/models/charges';

interface ChargesConnection {
  entries: Charges[];
  afterCursor: string | null;
  beforeCursor: string | null;
}

export interface ChargesList {
  charges: ChargesConnection;
}

export const chargesTypename = 'ChargeConnection';

const MAX_PAGE_SIZE = 100;

export const CHARGES_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

/*
$filters: ChargesFilterOptions
filters: $filters
*/

export const CHARGES_QUERY = gql`
  query chargeConnection($after: String, $before: String, $pageSize: Int) {
    charges(after: $after, before: $before, pageSize: $pageSize) {
      afterCursor
      beforeCursor
      entries {
        id
        chargeStatus
        chargeDueDate
        chargeInterest
        chargeTotalPaid
        chargeTotalBilled
        chargeTotalCharged
        chargeAppTotalFees
        chargePaymentMethod
        chargeCooperativeTotalValue
        chargeGenerationUnitTotalValue
        chargeGenerationUnitOemTotalValue
        chargeCooperativeSubscriptionValue
        chargeGenerationUnitRealStateTotalValue
        chargeGenerationUnitEquipmentRentTotalValue
        cooperative {
          id
          cooperativeAddressCity
          cooperativeAddressPostalCode
          cooperativeAddressState
          cooperativeAddressStreet
          cooperativeAddressDistrict
          cooperativeAddressComplement
          cooperativeDocumentId
          cooperativeLegalName
          cooperativeSubscriptionCostCents
          cooperativeHeadquarter {
            id
            cooperativeLegalName
          }
          financialAccount {
            id
            financialAccountLegalName
          }
        }
        generationUnit {
          id
          generationUnitLegalName
          generationUnitEnergySource
          generationUnitAddressState
          generationUnitPowerCapacity
          generationUnitCapacityFactorAverage
          powerDistributionUnitCredentialsUser
          cooperative {
            cooperativeLegalName
          }
          financialAccount {
            financialAccountLegalName
          }
          powerDistributionUnit {
            powerDistributionUnitLegalName
          }
        }
        commercialAgreement {
          id
          commercialAgreementStartValidity
          commercialAgreementHasPisCofins
          commercialAgreementHasExtraRemuneration
          commercialAgreementReceiptRule
          commercialAgreementLeaseValue
          commercialAgreementOemValue
          commercialAgreementHasOem
          commercialAgreementDiscountMin
          commercialAgreementDiscountMax
          commercialAgreementBaseCostPerCreditIcms
          commercialAgreementBaseCostPerCredit
          generationUnit {
            id
            generationUnitLegalName
          }
        }
        economyReport {
          id
          flagEconomy
          economyValue
          energyEconomy
          economyReportUrl
          economyReferenceDate
        }
        powerDistributionUnitBillData {
          id
          billTotalValue
        }
      }
    }
  }
`;
