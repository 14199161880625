import { gql } from '@apollo/client';

import { ConsumerUnitBillingCaptureStatusCounter } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

import { ConsumerUnitType } from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/models/consumerUnitBillData';

export interface ConsumerUnitBillingCaptureStatusCounterList {
  consumerUnitBillingCaptureStatusCounter: ConsumerUnitBillingCaptureStatusCounter;
}

const GENERATION_UNIT_TYPE = ConsumerUnitType.GENERATION;

export const GENERATION_UNIT_LEASE_CYCLES_SELECT_QUERY_VARIABLES = {
  variables: { consumerUnitType: GENERATION_UNIT_TYPE },
};

const CONSUMER_UNIT_TYPE = ConsumerUnitType.CONSUMER;

export const CONSUMER_UNIT_BILLING_CYCLES_SELECT_QUERY_VARIABLES = {
  variables: { consumerUnitType: CONSUMER_UNIT_TYPE },
};

export const consumerUnitBillingCaptureStatusCounterTypename =
  'ConsumerUnitBillingCaptureStatusCounter';

export const CONSUMER_UNIT_BILLING_CAPTURE_STATUS_COUNTER_QUERY = gql`
  query consumerUnitBillingCaptureStatusCounter($consumerUnitType: String) {
    consumerUnitBillingCaptureStatusCounter(consumerUnitType: $consumerUnitType) {
      consumerUnitBillingCaptureErrorStatusCount
      consumerUnitBillingCapturePendingStatusCount
      consumerUnitBillingCaptureUnavailableStatusCount
    }
  }
`;
