import { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import NexLogo from 'assets/logo/NexLogo';

import { MdHelp, MdReportProblem } from 'react-icons/md';

import { convertToBRL, getPartsFromDate } from 'utils/form';

import { ConsumptionGroupsType } from 'consumerUnits/model/consumerUnit';

import { EconomyReportDataProps } from 'economyReport/pages/EconomyReport';

import EconomyReportGroupB from 'assets/background/economy-report/EconomyReportGroupB';
import EconomyReportGroupA from 'assets/background/economy-report/EconomyReportGroupA';

import { TariffFlagModalityModalityFlagType } from 'tariffFlagTariffs/models/tariffFlagModality';

type MonthAndYearProps = {
  date: string;
  spliter: '/' | '-';
  options: Intl.DateTimeFormatOptions;
};

export default function FirstPageEconomyReport({
  economyReportData,
}: EconomyReportDataProps) {
  const {
    usedCredits = '0',
    economyValue = '0',
    economyReports = [],
    receivedCredits = '0',
    usedCreditsOnPeak = '0',
    usedCreditsOffPeak = '0',
    chargeTotalCharged = '0',
    accumulatedCredits = '0',
    receivedCreditsOnPeak = '0',
    receivedCreditsOffPeak = '0',
    generationUnitLegalName = '-',
    approximateChargeAmount = '0',
    consumerUnitAddressCity = '-',
    consumerUnitAddressState = '-',
    accumulatedCreditsOnPeak = '0',
    generationBillTotalValue = '0',
    consumerUnitAddressStreet = '-',
    accumulatedCreditsOffPeak = '0',
    generationUnitAddressCity = '-',
    generationUnitAddressState = '-',
    cooperativeMemberLegalName = '-',
    cooperativeMemberDocumentId = '-',
    generationUnitAddressStreet = '-',
    generationUnitPowerCapacity = '0',
    tariffFlagModalityModalityFlag = '-',
    chargeDueDate = new Date().getTime(),
    consumerUnitConsumptionGroupType = '-',
    billReferenceDate = new Date().getTime(),
    apportionmentCycleConsumerUnitPercentual = '0',
    consumerUnitPowerDistributionUnitIdentifier = '-',
    generationBillTotalValueAddChargeTotalCharged = '0',
  } = economyReportData || {};

  const HAS_CONSUMPTION_GROUP_TYPE_B = useMemo(
    () => consumerUnitConsumptionGroupType === ConsumptionGroupsType.B,
    [consumerUnitConsumptionGroupType]
  );

  const getMonthAndYear = useCallback(
    ({ date, spliter, options }: MonthAndYearProps) =>
      `${getPartsFromDate(date, options)
        .toUpperCase()
        .replace('.', '')}${spliter}${getPartsFromDate(date, {
        year: 'numeric',
        timeZone: 'UTC',
      })}`,
    []
  );

  const getTariffFlagModalityFlag = useCallback(
    () =>
      [
        {
          key: TariffFlagModalityModalityFlagType.GREEN,
          flag: 'Bandeira Verde',
          color: 'border-primary',
        },
        {
          key: TariffFlagModalityModalityFlagType.YELLOW,
          flag: 'Bandeira Amarela',
          color: 'border-yellow-500',
        },
        {
          key: TariffFlagModalityModalityFlagType.RED_ON_PEAK,
          flag: 'Bandeira Vermelha PT1',
          color: 'border-red-a1',
        },
        {
          key: TariffFlagModalityModalityFlagType.RED_ON_PEAK_TWO,
          flag: 'Bandeira Vermelha PT2',
          color: 'border-red-a1',
        },
      ].find(({ key }) => key === tariffFlagModalityModalityFlag),
    [tariffFlagModalityModalityFlag]
  );

  const HAS_CONSUMER_UNIT_INFO = useMemo(
    () => [
      {
        key: 'UNIDADE CONSUMIDORA',
        value: consumerUnitPowerDistributionUnitIdentifier,
      },
      {
        key: 'CNPJ/CPF',
        value: cooperativeMemberDocumentId,
      },
      {
        key: 'ENDEREÇO',
        value: `${consumerUnitAddressStreet}, ${consumerUnitAddressCity} - ${consumerUnitAddressState}`,
      },
    ],
    [
      consumerUnitAddressCity,
      consumerUnitAddressState,
      consumerUnitAddressStreet,
      cooperativeMemberDocumentId,
      consumerUnitPowerDistributionUnitIdentifier,
    ]
  );

  const HAS_GENERATION_UNIT_INFO = useMemo(
    () => [
      {
        key: 'POTÊNCIA INSTALADA',
        value: generationUnitPowerCapacity + 'MW',
      },
      {
        key: 'PERCENTUAL ALUGADO',
        value: apportionmentCycleConsumerUnitPercentual + '%',
      },
      {
        key: 'ENDEREÇO',
        value: `${generationUnitAddressStreet}, ${generationUnitAddressCity} - ${generationUnitAddressState}`,
      },
    ],
    [
      generationUnitAddressCity,
      generationUnitAddressState,
      generationUnitPowerCapacity,
      generationUnitAddressStreet,
      apportionmentCycleConsumerUnitPercentual,
    ]
  );

  const HAS_BILLING_INFO = useMemo(
    () => [
      {
        key: 'IDENTIFICADOR DA FATURA',
        value: `
          RE-${consumerUnitPowerDistributionUnitIdentifier}-${getMonthAndYear({
          spliter: '-',
          options: {
            month: '2-digit',
            timeZone: 'UTC',
          },
          date: billReferenceDate as string,
        })}`,
      },
      {
        key: 'MÊS DE REFERÊNCIA',
        value: getMonthAndYear({
          spliter: '/',
          options: {
            month: 'short',
            timeZone: 'UTC',
          },
          date: billReferenceDate as string,
        }),
      },
      {
        key: 'BANDEIRA TARIFÁRIA',
        value: getTariffFlagModalityFlag()?.flag,
      },
    ],
    [
      getMonthAndYear,
      billReferenceDate,
      getTariffFlagModalityFlag,
      consumerUnitPowerDistributionUnitIdentifier,
    ]
  );

  return (
    <div
      id="economy-report-first-page"
      className="flex flex-col w-full items-center justify-start h-screen bg-white p-7 gap-1 font-rubik relative"
      style={{
        width: '210mm',
        height: '297mm',
      }}
    >
      <header className="w-full flex items-center justify-between pb-6">
        <h1
          className="font-bold"
          id="economy-report-title"
          style={{
            fontSize: '2rem',
            lineHeight: '2rem',
          }}
        >
          RELATÓRIO DE ECONOMIA
        </h1>
        <NexLogo />
      </header>
      <section className="flex w-full items-start justify-between pl-6 gap-6">
        <div className="flex flex-col items-start justify-start gap-6">
          <div className="flex items-center justify-start">
            <h1
              className="font-bold"
              style={{
                maxWidth: '450px',
                fontSize: '1.75rem',
                lineHeight: '2rem',
              }}
            >
              {cooperativeMemberLegalName}
            </h1>
          </div>
          <div className="flex flex-col items-start justify-center gap-1">
            {HAS_CONSUMER_UNIT_INFO.map((item) => (
              <p
                key={item.key}
                className="font-light text-sm"
                style={{
                  letterSpacing: '.05rem',
                }}
              >
                {item.key}:{' '}
                <span
                  className="font-bold"
                  style={{
                    letterSpacing: 'normal',
                  }}
                >
                  {item.value || '-'}
                </span>
              </p>
            ))}
          </div>
        </div>
        <div
          id="economy-report-billing-info"
          className={classNames('flex flex-col bg-gray-dark200 gap-4', {
            [getTariffFlagModalityFlag()?.color as string]:
              !!getTariffFlagModalityFlag(),
          })}
          style={{
            borderLeftWidth: '3px',
            padding: '1.5rem 2rem',
          }}
        >
          {HAS_BILLING_INFO.map((item) => (
            <div key={item.key}>
              <p
                className="font-light text-xs"
                style={{
                  lineHeight: '.5rem',
                  letterSpacing: '.05rem',
                }}
              >
                {item.key}:{' '}
              </p>
              <span
                className="font-bold text-sm"
                style={{
                  letterSpacing: 'normal',
                }}
              >
                {item.value || '-'}
              </span>
            </div>
          ))}
        </div>
      </section>
      <section
        className="flex flex-col w-full items-start justify-start"
        style={{
          padding: '1rem 0',
        }}
      >
        <div
          className="flex w-full bg-gray-dark200 relative"
          style={{
            padding: '.5rem 1rem .5rem 1rem',
          }}
        >
          <div
            className="flex flex-col"
            style={{
              width: HAS_CONSUMPTION_GROUP_TYPE_B ? '600px' : 'inherit',
            }}
          >
            <div
              className="flex flex-col gap-1"
              style={{
                padding: '1rem 1rem .5rem 1rem',
              }}
            >
              <div
                className={classNames(
                  'flex items-center justify-between border-b-2 border-gray gap-4',
                  {
                    'grid grid-cols-3': !HAS_CONSUMPTION_GROUP_TYPE_B,
                  }
                )}
              >
                <h2
                  id="economy-report-credits-title"
                  className="leading-5 pb-1"
                  style={{
                    width: 'max-content',
                  }}
                >
                  CARTEIRA DE <br /> CRÉDITOS
                </h2>
                {!HAS_CONSUMPTION_GROUP_TYPE_B && (
                  <>
                    <h3
                      id="economy-report-credits-title"
                      className="font-semibold text-center text-sm leading-4"
                    >
                      PONTA
                    </h3>
                    <h3
                      id="economy-report-credits-title"
                      className="font-semibold text-center text-sm leading-4"
                    >
                      FORA <br />
                      PONTA
                    </h3>
                  </>
                )}
              </div>
              <div
                className={classNames(
                  'flex items-center justify-between gap-4 pt-1 pb-1',
                  {
                    'grid grid-cols-3': !HAS_CONSUMPTION_GROUP_TYPE_B,
                  }
                )}
              >
                <h3
                  id="economy-report-credits-title"
                  className="font-semibold leading-4"
                  style={{
                    fontSize: '0.8rem',
                  }}
                >
                  CRÉDITOS <br /> ACUMULADOS¹
                </h3>
                {!HAS_CONSUMPTION_GROUP_TYPE_B ? (
                  <>
                    <p
                      id="economy-report-credits-value"
                      className="font-normal text-2xl text-center"
                    >
                      {Math.round(+accumulatedCreditsOnPeak)}
                    </p>
                    <p
                      id="economy-report-credits-value"
                      className="font-normal text-2xl text-center"
                    >
                      {Math.round(+accumulatedCreditsOffPeak)}
                    </p>
                  </>
                ) : (
                  <p
                    id="economy-report-credits-value"
                    className="font-normal text-2xl text-center"
                  >
                    {Math.round(+accumulatedCreditsOffPeak || +accumulatedCredits)}
                  </p>
                )}
              </div>
              <div
                className={classNames(
                  'flex items-center justify-between border-b-2 border-t-2 border-gray pt-2 pb-2 gap-4',
                  {
                    'grid grid-cols-3': !HAS_CONSUMPTION_GROUP_TYPE_B,
                  }
                )}
              >
                <h3
                  id="economy-report-credits-title"
                  className="font-semibold leading-4"
                  style={{
                    fontSize: '0.8rem',
                  }}
                >
                  CRÉDITOS <br /> RECEBIDOS²
                </h3>
                {!HAS_CONSUMPTION_GROUP_TYPE_B ? (
                  <>
                    <p
                      id="economy-report-credits-value"
                      className="font-normal text-2xl text-center"
                    >
                      {Math.round(+receivedCreditsOnPeak)}
                    </p>
                    <p
                      id="economy-report-credits-value"
                      className="font-normal text-2xl text-center"
                    >
                      {Math.round(+receivedCreditsOffPeak)}
                    </p>
                  </>
                ) : (
                  <p
                    id="economy-report-credits-value"
                    className="font-normal text-2xl text-center"
                  >
                    {Math.round(+receivedCreditsOffPeak || +receivedCredits)}
                  </p>
                )}
              </div>
              <div
                className={classNames(
                  'flex items-center justify-between gap-4 pt-1',
                  {
                    'grid grid-cols-3': !HAS_CONSUMPTION_GROUP_TYPE_B,
                  }
                )}
              >
                <h3
                  id="economy-report-credits-title"
                  className="font-semibold leading-4"
                  style={{
                    fontSize: '0.8rem',
                  }}
                >
                  CRÉDITOS <br /> UTILIZADOS²
                </h3>
                {!HAS_CONSUMPTION_GROUP_TYPE_B ? (
                  <>
                    <p
                      id="economy-report-credits-value"
                      className="font-normal text-2xl text-center"
                    >
                      {Math.round(+usedCreditsOnPeak)}
                    </p>
                    <p
                      id="economy-report-credits-value"
                      className="font-normal text-2xl text-center"
                    >
                      {Math.round(+usedCreditsOffPeak)}
                    </p>
                  </>
                ) : (
                  <p
                    id="economy-report-credits-value"
                    className="font-normal text-2xl text-center"
                  >
                    {Math.round(+usedCreditsOffPeak || +usedCredits)}
                  </p>
                )}
              </div>
            </div>
            <div
              className={classNames(
                'flex items-center justify-between bg-primary gap-4',
                {
                  'grid grid-cols-3': !HAS_CONSUMPTION_GROUP_TYPE_B,
                }
              )}
              style={{
                padding: '.75rem 1rem',
              }}
            >
              <h3
                id="economy-report-credits-title"
                className="font-semibold leading-4"
                style={{
                  fontSize: '0.8rem',
                }}
              >
                CRÉDITOS <br /> RESTANTES²
              </h3>
              {!HAS_CONSUMPTION_GROUP_TYPE_B ? (
                <>
                  <p
                    id="economy-report-credits-value"
                    className="font-semibold text-2xl text-center"
                  >
                    {Math.round(
                      +accumulatedCreditsOnPeak +
                        +receivedCreditsOnPeak -
                        +usedCreditsOnPeak
                    )}
                  </p>
                  <p
                    id="economy-report-credits-value"
                    className="font-semibold text-2xl text-center"
                  >
                    {Math.round(
                      +accumulatedCreditsOffPeak +
                        +receivedCreditsOffPeak -
                        +usedCreditsOffPeak
                    )}
                  </p>
                </>
              ) : (
                <p
                  id="economy-report-credits-value"
                  className="font-normal text-2xl text-center"
                >
                  {Math.round(+accumulatedCredits + +receivedCredits - +usedCredits)}
                </p>
              )}
            </div>
            <div
              className="flex"
              style={{
                padding: '.5rem 1rem .25rem 1rem',
              }}
            >
              <span id="economy-report-credits-legend" className="text-xs leading-4">
                ¹ Saldo de créditos referente à períodos anteriores. <br />² Créditos
                referentes ao ciclo de faturamento atual.
              </span>
            </div>
          </div>
          <div className="flex flex-col w-full p-4 gap-2">
            <h1
              id="economy-report-generation-unit-name"
              className="font-bold text-2xl pb-2 leading-6"
              style={{
                maxWidth: '350px',
              }}
            >
              {generationUnitLegalName}
            </h1>
            {HAS_GENERATION_UNIT_INFO.map((item) => (
              <div key={item.key}>
                <h3
                  className="font-semibold leading-4"
                  style={{
                    fontSize: '0.8rem',
                  }}
                >
                  {item.key}
                </h3>
                <p className="text-xs">{item.value}</p>
              </div>
            ))}
          </div>
          {HAS_CONSUMPTION_GROUP_TYPE_B ? (
            <EconomyReportGroupB className="absolute bottom-0 right-0" />
          ) : (
            <EconomyReportGroupA className="absolute bottom-0 right-0" />
          )}
        </div>
        <div
          className="flex gap-6 items-center justify-start bg-gray w-full"
          style={{
            padding: '1rem 1.5rem',
          }}
        >
          <div className="flex items-center justify-start gap-2">
            <MdReportProblem size={18} />
            <h1
              id="economy-report-notice-title"
              className="font-bold text-lg"
              style={{
                lineHeight: '1rem',
              }}
            >
              AVISOS
            </h1>
          </div>
          <p
            id="economy-report-notice-message"
            className="w-full flex"
            style={{
              fontSize: '0.65rem',
              lineHeight: '.75rem',
            }}
          >
            Nenhum aviso disponível
          </p>
        </div>
      </section>
      <section className="flex w-full items-start justify-between">
        <div className="flex flex-col items-start justify-start gap-4">
          <div className="flex flex-col items-start justify-start w-full gap-4">
            <h1
              id="economy-report-bill-title"
              className="font-bold text-2xl leading-6"
              style={{
                padding: '0 1rem',
              }}
            >
              Entenda a sua fatura com a NEX
            </h1>
            <div
              className="flex items-center w-full justify-between"
              style={{
                padding: '0 1rem',
              }}
            >
              <h3
                className="font-semibold leading-4"
                style={{
                  fontSize: '0.8rem',
                }}
              >
                FATURA ATUAL DA <br /> CONCESSIONÁRIA
              </h3>
              <h2 className="text-2xl">{convertToBRL(generationBillTotalValue)}</h2>
            </div>
            <div
              className="flex items-center w-full justify-between border-primary"
              style={{
                borderWidth: '3px',
                padding: '.5rem 1rem',
              }}
            >
              <div className="flex flex-col items-start justify-start">
                <h3
                  id="economy-report-bill-title"
                  className="font-semibold leading-4"
                  style={{
                    fontSize: '0.8rem',
                  }}
                >
                  FATURA DA NEX ENERGY
                </h3>
                <p id="economy-report-bill-due-date" className="text-xs">
                  Vencimento -{' '}
                  {getMonthAndYear({
                    spliter: '/',
                    options: {
                      timeZone: 'UTC',
                      month: '2-digit',
                    },
                    date: chargeDueDate as string,
                  })}
                </p>
              </div>
              <h2 id="economy-report-bill-value" className="text-2xl">
                {convertToBRL(chargeTotalCharged)}
              </h2>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-4">
            <h1
              id="economy-report-bill-title"
              className="font-bold text-2xl leading-6"
              style={{
                padding: '0 1rem',
              }}
            >
              Sua economia esse mês
            </h1>
            <div
              className="flex items-center w-full justify-between"
              style={{
                padding: '0 1rem',
              }}
            >
              <h3
                className="font-semibold leading-4"
                style={{
                  fontSize: '0.8rem',
                }}
              >
                TOTAL CONCESSIONÁRIA <br /> + NEX ENERGY
              </h3>
              <h2 className="text-2xl">
                {convertToBRL(generationBillTotalValueAddChargeTotalCharged)}
              </h2>
            </div>
            <div
              className="flex items-center w-full justify-between bg-primary"
              style={{
                padding: '.75rem 1rem',
              }}
            >
              <h3
                id="economy-report-bill-title"
                className="font-semibold leading-4"
                style={{
                  fontSize: '0.8rem',
                }}
              >
                ECONOMIA <br /> DESSE MÊS
              </h3>
              <h2 id="economy-report-bill-value" className="font-semibold text-2xl">
                {convertToBRL(economyValue)}
              </h2>
            </div>
            <div
              className="flex gap-6 items-center justify-start bg-gray relative mt-2"
              style={{
                padding: '.75rem 1.25rem',
              }}
            >
              <div
                className="absolute bg-gray w-5 h-5 rotate-45"
                style={{
                  top: '-.5rem',
                  right: '13.5rem',
                }}
              />
              <div className="flex items-center justify-start gap-6">
                <MdHelp size={18} />
                <p
                  id="economy-report-bill-notice"
                  style={{
                    fontSize: '0.65rem',
                    lineHeight: '.75rem',
                  }}
                >
                  Economia calculada com base no valor projetado de como seria a
                  fatura do <br />
                  consumidor sem a NEX. Aproximadamente este valor seria{' '}
                  <strong>{convertToBRL(approximateChargeAmount)}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex flex-col bg-primary gap-1"
          style={{
            padding: '1rem 1.5rem',
          }}
        >
          <h3 id="economy-report-bill-title" className="font-bold">
            $ CARTEIRA DE ECONOMIA
          </h3>
          {economyReports &&
            economyReports.map((item, index) => (
              <div
                key={index}
                className={classNames(
                  'flex w-full items-center justify-between pb-1 border-b border-gray-dark550',
                  {
                    'border-none': index === economyReports.length - 1,
                  }
                )}
              >
                <p id="economy-report-bill-month" className="text-xs">
                  {getMonthAndYear({
                    date: item.economyReferenceDate,
                    options: {
                      month: 'short',
                      timeZone: 'UTC',
                    },
                    spliter: '/',
                  }).toUpperCase()}
                </p>
                <p id="economy-report-bill-month-value" className="text-xs">
                  {convertToBRL(item.economyValue)}
                </p>
              </div>
            ))}
          <div
            className="flex flex-col items-start justify-center bg-white"
            style={{
              padding: '.5rem .75rem',
            }}
          >
            <p id="economy-report-bill-title" className="text-xs">
              TOTAL ECONOMIZADO
            </p>
            <h1
              id="economy-report-bill-value-global"
              className="font-semibold text-2xl leading-5"
            >
              {convertToBRL(
                String(
                  economyReports?.reduce((acc, item) => acc + +item.economyValue, 0)
                )
              )}
            </h1>
          </div>
        </div>
      </section>
      <footer className="w-56 h-2 bg-gray-dark200 bottom-6 left-6 absolute" />
    </div>
  );
}
