import { CSSProperties } from 'react';

interface Props {
  style?: CSSProperties;
  className?: string;
}

export default function NexLogo({ style, className }: Props) {
  return (
    <div
      className={className || 'flex items-center'}
      style={
        style || {
          width: '75px',
          height: '35px',
        }
      }
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 46 26"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.8452 4.60167L43.0219 4.34381L43.5959 3.50609L45.0765 1.34573L45.0973 1.31623L45.248 1.09686L46 0H42.8011C42.5296 0.0179602 42.2712 0.080821 42.0322 0.182168C41.6257 0.354073 41.288 0.654266 41.0399 1.01604L41.0062 1.06607L40.8685 1.2662L39.3321 3.50738L37.9878 5.46889L36.8046 3.74343L36.6436 3.50738L35.0253 1.14432L34.9877 1.09044L34.8487 0.887749C34.4214 0.347659 33.7564 0 33.0083 0H29.9783V0.00641437L30.7043 1.06479L30.7342 1.10712L30.8913 1.33547L32.3784 3.50609L32.9577 4.35151L33.107 4.5696L33.1317 4.6068L34.6474 6.81462L34.672 6.85183L35.8552 8.57729L34.6772 10.2951L34.6591 10.3207L33.1382 12.5388L32.3849 13.6382L30.8926 15.814L29.9809 17.1443H33.0083C33.8136 17.1443 34.524 16.7415 34.9422 16.127L36.6488 13.6382L36.8098 13.4047L37.9878 11.687L39.3256 13.6382L41.0321 16.127C41.4504 16.7415 42.1608 17.1443 42.966 17.1443H45.9948L45.0817 15.8127L43.5907 13.6382L42.8362 12.5388L41.3153 10.3207L40.1191 8.57601L41.327 6.81462L42.8426 4.60423L42.8452 4.60167Z"
          fill="black"
        />
        <path
          d="M14.3761 0V17.1443H11.5825C11.3331 17.1443 11.0889 17.0661 10.8876 16.9211C10.7552 16.8249 10.6331 16.7158 10.524 16.5953C10.4876 16.5555 10.4564 16.5119 10.4253 16.4682L10.2058 16.1488L8.4836 13.6382L6.20944 10.3207L3.80541 6.81462L3.54955 6.44131V17.1443H0V0H2.62612C2.9638 0 3.2911 0.12059 3.54566 0.341244L3.55085 0.345093C3.65605 0.434894 3.75216 0.534958 3.83788 0.64272L4.04698 0.946761L5.80163 3.50609L8.07059 6.81462L10.4746 10.3207L10.8889 10.9237V0H14.3761Z"
          fill="black"
        />
        <path
          d="M20.2856 3.50609V6.81462H26.3262V10.3207H20.2856V13.6382H29.6446V13.6972L27.7276 16.4939L27.2808 17.1443H16.7361V0H27.2847L27.7276 0.646568L29.6446 3.44195V3.50609H20.2856Z"
          fill="black"
        />
        <path
          d="M4.43924 25.3559L4.43534 25.9409H1.12866V20.6414H4.43534V21.2263H1.85598V22.9864H3.9457V23.5483H1.85598V25.3572H4.43924V25.3559Z"
          fill="black"
        />
        <path
          d="M12.8787 20.6402V25.9398H12.5151C12.4579 25.9398 12.4099 25.9295 12.3709 25.9102C12.332 25.891 12.2943 25.8576 12.2566 25.8102L9.15255 21.8153C9.15775 21.8769 9.16164 21.9372 9.16424 21.9962C9.16684 22.0552 9.16814 22.1104 9.16814 22.163V25.9385H8.53174V20.6389H8.90579C8.93825 20.6389 8.96553 20.6402 8.98761 20.644C9.00969 20.6479 9.03047 20.6543 9.04735 20.662C9.06424 20.671 9.08242 20.6825 9.0993 20.6992C9.11619 20.7159 9.13567 20.7351 9.15515 20.7608L12.2592 24.7518C12.254 24.6877 12.2501 24.6261 12.2462 24.5645C12.2423 24.5042 12.241 24.4465 12.241 24.3926V20.6389H12.8787V20.6402Z"
          fill="black"
        />
        <path
          d="M20.6363 25.3559L20.6324 25.9409H17.3257V20.6414H20.6324V21.2263H18.053V22.9864H20.1427V23.5483H18.053V25.3572H20.6363V25.3559Z"
          fill="black"
        />
        <path
          d="M28.6938 25.9409H28.0496C27.9172 25.9409 27.8198 25.8909 27.7574 25.7895L26.3651 23.8947C26.3223 23.8357 26.2768 23.7934 26.2288 23.7677C26.1807 23.7421 26.1041 23.7292 26.0028 23.7292H25.4521V25.9409H24.73V20.6414H26.2469C26.5859 20.6414 26.8795 20.6747 27.1262 20.7427C27.373 20.8107 27.5769 20.9082 27.7379 21.0365C27.899 21.1648 28.0185 21.32 28.0951 21.5009C28.173 21.6818 28.2107 21.8845 28.2107 22.109C28.2107 22.2963 28.1808 22.472 28.1211 22.6337C28.0613 22.7966 27.9743 22.9428 27.8613 23.0724C27.7483 23.202 27.6094 23.3123 27.4457 23.4034C27.2821 23.4945 27.0963 23.5637 26.8898 23.6099C26.9821 23.6612 27.0626 23.7369 27.1301 23.8357L28.6938 25.9409ZM26.2145 23.2071C26.4236 23.2071 26.6093 23.1814 26.7704 23.1314C26.9314 23.0814 27.0665 23.0095 27.1743 22.9172C27.2834 22.8248 27.3652 22.7145 27.4197 22.5862C27.4743 22.4579 27.5016 22.3168 27.5016 22.1603C27.5016 21.8447 27.3964 21.6074 27.1873 21.447C26.9782 21.2866 26.6626 21.2071 26.2444 21.2071H25.4508V23.2084H26.2145V23.2071Z"
          fill="black"
        />
        <path
          d="M36.8033 23.3919V25.4266C36.5332 25.619 36.2461 25.7627 35.9396 25.8576C35.6344 25.9526 35.2993 26 34.9344 26C34.5019 26 34.1135 25.9346 33.7668 25.8025C33.42 25.6703 33.1239 25.4856 32.8771 25.2483C32.6303 25.0109 32.442 24.7248 32.3096 24.3926C32.1771 24.059 32.1108 23.6934 32.1108 23.2944C32.1108 22.8903 32.1758 22.5209 32.3044 22.1886C32.4329 21.8551 32.6161 21.5703 32.8524 21.3329C33.0901 21.0956 33.3784 20.9109 33.7174 20.78C34.0564 20.6492 34.4369 20.5837 34.8551 20.5837C35.0668 20.5837 35.2643 20.5991 35.4474 20.6299C35.6292 20.6607 35.7981 20.7056 35.9526 20.7633C36.1072 20.8211 36.2513 20.8916 36.3838 20.9737C36.5163 21.0558 36.6397 21.1495 36.7539 21.2534L36.5487 21.5792C36.5163 21.628 36.4747 21.6601 36.4228 21.6742C36.3721 21.6883 36.315 21.6755 36.2526 21.6395C36.1929 21.6049 36.124 21.5626 36.0461 21.5138C35.9682 21.4651 35.8747 21.4176 35.763 21.3714C35.6526 21.3265 35.5214 21.2868 35.3695 21.2547C35.2188 21.2226 35.0396 21.2072 34.8318 21.2072C34.5292 21.2072 34.2564 21.256 34.0122 21.3535C33.7681 21.451 33.559 21.5895 33.3875 21.7717C33.2148 21.9526 33.0823 22.1719 32.9901 22.4298C32.8979 22.6876 32.8511 22.9763 32.8511 23.297C32.8511 23.6293 32.8992 23.9269 32.9953 24.1899C33.0914 24.4529 33.2291 24.6761 33.407 24.8596C33.5849 25.043 33.8018 25.1828 34.0564 25.279C34.311 25.3753 34.5967 25.4227 34.9136 25.4227C35.163 25.4227 35.385 25.3945 35.5786 25.3393C35.7721 25.2842 35.9617 25.2072 36.1461 25.1084V23.9436H35.311C35.263 23.9436 35.2253 23.9295 35.1967 23.9025C35.1681 23.8756 35.1539 23.8422 35.1539 23.8025V23.3958H36.8033V23.3919Z"
          fill="black"
        />
        <path
          d="M42.8842 23.8281V25.9397H42.1582V23.8281L40.1931 20.6401H40.8295C40.8945 20.6401 40.9451 20.6555 40.9828 20.6863C41.0204 20.7171 41.0529 20.7569 41.0802 20.8069L42.3088 22.8672C42.3543 22.9532 42.3945 23.034 42.4322 23.1097C42.4699 23.1854 42.5023 23.261 42.5296 23.3367C42.5569 23.2598 42.5881 23.1841 42.6231 23.1097C42.6582 23.034 42.6972 22.9532 42.7387 22.8672L43.9635 20.8069C43.9855 20.7646 44.0167 20.7274 44.057 20.6927C44.0972 20.6581 44.1479 20.6414 44.2102 20.6414H44.8505L42.8842 23.8281Z"
          fill="black"
        />
      </svg>
    </div>
  );
}
