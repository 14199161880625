import React from 'react';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import masks, { MaskInterface } from 'utils/masks';

export type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & {
    mask?: MaskInterface;
    error?: string | FieldError;
    ref?: React.Ref<HTMLInputElement>;
    tooltipReference?: React.Ref<HTMLInputElement>;
  },
  HTMLInputElement
>;

export const BaseInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      mask,
      name,
      type,
      error,
      disabled,
      className,
      minLength,
      maxLength,
      autoComplete,
      tooltipReference,
      ...props
    },
    ref
  ) => {
    const [inputError, setInputError] = React.useState<
      string | FieldError | undefined
    >(error);

    const locale = 'pt-BR';

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (mask) {
        event.target.value = masks[mask](event.target.value);
      }
      setInputError('');

      props.onChange && props.onChange(event);
    }

    function handleValidateDays(month: number, year: number) {
      switch (month) {
        case 2:
          if (year % 4) {
            return 28;
          }
          if (year % 100) {
            return 29;
          }
          if (year % 400) {
            return 28;
          }
          return 29;
        case 9:
        case 4:
        case 6:
        case 11:
          return 30;
        default:
          return 31;
      }
    }

    function handleValidateMaskOnBlur(event: React.FocusEvent<HTMLInputElement>) {
      switch (mask) {
        case 'date':
          const splitedDate = event.target.value.split('/');

          if (event.target.value.split('/').length > 2) {
            const day = Number(splitedDate[0]);
            const month = Number(splitedDate[1]);
            const year = Number(splitedDate[2]);

            if (
              month < 1 ||
              month > 12 ||
              year < 1 ||
              handleValidateDays(month, year) < day
            ) {
              setInputError('Data inválida');
            } else {
              return (event.target.value = new Date(
                year,
                month - 1,
                day
              ).toLocaleDateString(locale));
            }
          } else if (event.target.value.split('/').length === 2) {
            const day = 1;
            const month = Number(splitedDate[0]);
            const year = Number(splitedDate[1]);

            if (
              month < 1 ||
              month > 12 ||
              year < 1 ||
              handleValidateDays(month, year) < day
            ) {
              setInputError('Data inválida');
            } else {
              return (event.target.value =
                new Date(year, month - 1, day)
                  .toLocaleDateString(locale)
                  .split('/')[1] +
                '/' +
                year);
            }
          } else if (event.target.value.length < 3) {
            setInputError('Data inválida');
          }
          break;
        case 'decimalEightDigitsPrecision':
          const splitedDecimal = event.target.value.split(',');

          if (splitedDecimal[0] === '' && event.target.value !== '') {
            return (event.target.value = '0,' + splitedDecimal[1]);
          }
      }

      props.onBlur && props.onBlur(event);
    }

    return (
      <div ref={tooltipReference}>
        <input
          {...props}
          ref={ref}
          name={name}
          disabled={disabled}
          type={type || 'text'}
          minLength={minLength}
          maxLength={maxLength}
          autoComplete={autoComplete}
          className={classNames(
            className ||
              'appearence-none focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-dark500 bg-gray-dark600 shadow-none rounded-md hover:border-primary placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark400',
            {
              'appearence-none border-gray-dark600 bg-gray-dark700 block text-gray-dark550 cursor-default shadow-none rounded-md hover:border-transparent':
                disabled,
              'appearence-none focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-red-500 bg-transparent shadow-none rounded-md hover:border-red-500 placeholder-red500 focus-within:text-red-500 text-red-500':
                !!error || !!inputError,
            }
          )}
          onChange={handleOnChange}
          onBlur={handleValidateMaskOnBlur}
        />
        {(error || inputError) && (
          <p className="text-red-500 text-xs mt-3 font-normal text-left absolute">
            {error || inputError}
          </p>
        )}
      </div>
    );
  }
);

export default BaseInput;
