import { useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { MdCheck, MdCircle } from 'react-icons/md';

import { Tooltip } from 'ui/components/tooltip';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';

import SuccessIcon from 'assets/icons/list/SuccessIcon';

import { Charges, ChargeStatus } from 'charges/models/charges';
import { EconomyReport } from 'economyReport/model/economyReport';

import {
  convertToBRL,
  convertToDecimal,
  formatDateToLocaleDateString,
} from 'utils/form';

type Props = {
  charges: Charges;
};

const CREDIT_DISTRIBUTION_ROUTE =
  '/dashboard/billing-cycle/apportionment-cycle/credit-distribution';

const DATE_CONFIG_OPTIONS = {
  options: { month: '2-digit', year: 'numeric' } as Intl.DateTimeFormatOptions,
};

export default function ChargessTableItem({
  charges: {
    id,
    chargeStatus,
    chargeDueDate,
    chargeInterest,
    generationUnit,
    chargeInvoiceUrl,
    chargeCreatedDate,
    chargePaymentDate,
    chargeTotalBilled,
    chargeTotalCharged,
    chargeAppTotalFees,
    chargeCooperativeTotalValue,
    powerDistributionUnitBillData,
    chargeGenerationUnitTotalValue,
    economyReport: { economyValue = '0' } = {} as EconomyReport,
  },
}: Props) {
  const { push } = useHistory();

  const ECONOMY_PERCENTAGE_VALUE = useMemo(() => {
    return convertToDecimal(
      (
        (+economyValue /
          (+chargeTotalCharged +
            +economyValue +
            +powerDistributionUnitBillData?.billTotalValue)) *
        100
      ).toFixed(2)
    );
  }, [economyValue, chargeTotalCharged, powerDistributionUnitBillData]);

  const STATUS = useMemo(() => {
    const status = {
      [ChargeStatus.PENDING]: 'Pendente',
      [ChargeStatus.OPENED]: 'Em Aberto',
      [ChargeStatus.CANCELED]: 'Cancelado',
      [ChargeStatus.DEFAULTER]: 'Inadimplente',
      [ChargeStatus.OVERDUE]: (
        <div className="flex items-center justify-start gap-4">
          <MdCircle className="text-red-500" size={10} />
          <p className="font-semibold">Vencido</p>
        </div>
      ),
      [ChargeStatus.PAYED]: (
        <div className="flex items-center justify-start gap-2">
          <MdCheck className="text-primary" size={20} />
          <p className="text-primary font-semibold">Pago</p>
        </div>
      ),
    };

    return status[chargeStatus];
  }, [chargeStatus]);

  return (
    <TableBodyRow dataTestId={`charges-row-id-${id}`}>
      <TableBodyColumn>
        {formatDateToLocaleDateString(
          powerDistributionUnitBillData.billReferenceDate,
          {
            year: DATE_CONFIG_OPTIONS.options.year,
            month: DATE_CONFIG_OPTIONS.options.month,
          }
        )}
      </TableBodyColumn>
      <TableBodyColumn>
        {
          powerDistributionUnitBillData?.consumerUnit?.cooperativeMember
            .cooperativeMemberLegalName
        }
      </TableBodyColumn>
      <TableBodyColumn>
        {
          powerDistributionUnitBillData?.consumerUnit
            ?.consumerUnitPowerDistributionUnitIdentifier
        }
      </TableBodyColumn>
      <TableBodyColumn>
        <Tooltip
          arrowPosition="top"
          label={
            <div className="flex flex-col items-start justify-center">
              <p className="font-light text-sm">
                NEX:
                <span className="font-semibold leading-5 ml-2">
                  {convertToBRL(chargeAppTotalFees)}
                </span>
              </p>
              <p className="font-light text-sm ">
                Cooperativa:
                <span className="font-semibold leading-5 ml-2">
                  {convertToBRL(chargeCooperativeTotalValue)}
                </span>
              </p>
              <p className="font-light text-sm">
                Gerador:
                <span className="font-semibold leading-5 ml-2">
                  {convertToBRL(chargeGenerationUnitTotalValue)}
                </span>
              </p>
            </div>
          }
          placement="bottom"
          offset={{ mainAxis: 5 }}
        >
          <p className="underline">{convertToBRL(chargeTotalBilled)}</p>
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn>
        <Tooltip
          arrowPosition="top"
          label={
            <div className="flex flex-col gap-4 items-start justify-center">
              <div className="flex flex-col items-start justify-center">
                <p className="font-light text-sm">NEX:</p>
                <p className="font-light text-sm">
                  Juros:
                  <span className="font-semibold leading-5 ml-2">-</span>
                </p>
                <p className="font-light text-sm">
                  Multa:
                  <span className="font-semibold leading-5 ml-2">-</span>
                </p>
              </div>
              <div className="flex flex-col items-start justify-center">
                <p className="font-light text-sm">Gerador:</p>
                <p className="font-light text-sm">
                  Juros:
                  <span className="font-semibold leading-5 ml-2">-</span>
                </p>
                <p className="font-light text-sm">
                  Multa:
                  <span className="font-semibold leading-5 ml-2">-</span>
                </p>
              </div>
            </div>
          }
          placement="bottom"
          offset={{ mainAxis: 5 }}
        >
          <p className="underline">{convertToBRL(chargeInterest)}</p>
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(chargeCreatedDate)}
      </TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(chargeDueDate)}
      </TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(chargePaymentDate)}
      </TableBodyColumn>
      <TableBodyColumn>{STATUS}</TableBodyColumn>
      <TableBodyColumn>
        <Tooltip
          label={'Distribuição de créditos'}
          placement="bottom"
          offset={{ mainAxis: 5 }}
        >
          <span
            className="underline font-bold cursor-pointer"
            onClick={() => {
              push({
                pathname: CREDIT_DISTRIBUTION_ROUTE,
                state: {
                  generationUnitId: generationUnit.id,
                  apportionmentCycleConsumerUnitReferenceMonth:
                    powerDistributionUnitBillData.billReferenceDate,
                },
              });
            }}
          >
            {convertToBRL(economyValue)}
          </span>
          ({ECONOMY_PERCENTAGE_VALUE} %)
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn>
        <a href={chargeInvoiceUrl} target="_blank" rel="noreferrer">
          <SuccessIcon />
        </a>
      </TableBodyColumn>
    </TableBodyRow>
  );
}
