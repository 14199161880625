import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';

import { ConsumerUnitType } from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/models/consumerUnitBillData';

export type ConsumerUnitBillingCaptureId = number;

export enum ConsumerUnitBillingCaptureStatus {
  ERROR = 'ERROR',
  MANUAL = 'MANUAL',
  SUCCESS = 'SUCCESS',
  WAITING = 'WAITING',
}

export interface ConsumerUnitBillingCaptureUploadBillInput {
  consumerUnitUploadBillingCaptureFileMetadata?: File | null;
  consumerUnitUploadBillingCaptureBillConsumerUnitId: number;
  consumerUnitUploadBillingCaptureBillReferenceMonth: string;
  consumerUnitUploadBillingCaptureBillConsumerUnitType: ConsumerUnitType;
  consumerUnitUploadBillingCapturePowerDistributionUnitIdentifier: string;
}

export type ConsumerUnitBillingCaptureStatusCounter = {
  consumerUnitBillingCaptureErrorStatusCount: number;
  consumerUnitBillingCaptureUnavailableCount: number;
  consumerUnitBillingCapturePendingStatusCount: number;
};

export type ConsumerUnitBillingCapture = {
  id: ConsumerUnitBillingCaptureId;
  consumerUnitId: number;
  consumerUnit?: ConsumerUnit;
  powerDistributionUnitId: number;
  consumerUnitBillingCaptureUrl?: string;
  consumerUnitBillingCaptureError?: string | null;
  consumerUnitPowerDistributionUnitIdentifier: string;
  consumerUnitBillingCaptureReferenceMonth: string | null;
  consumerUnitBillingCaptureStatus: ConsumerUnitBillingCaptureStatus;
};
