import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';
import { VscCircleOutline } from 'react-icons/vsc';
import { MdDone } from 'react-icons/md';
import { Tooltip } from 'ui/components/tooltip';
import { TariffFlagModality } from 'tariffFlagTariffs/models/tariffFlagModality';
import { convertToDecimal } from 'utils/form';

type Props = {
  id: number;
  tariffFlagModality: TariffFlagModality;
};

interface TariffFlagValidityFormatConfig {
  locale: string;
  options: Intl.DateTimeFormatOptions;
}

const TARIFF_FLAG_VALIDITY_FORMAT_CONFIG: TariffFlagValidityFormatConfig = {
  locale: 'pt-BR',
  options: { month: '2-digit', year: '2-digit' },
};

const COLOR_MODALITY = {
  GREEN: '#27AE60',
  YELLOW: '#F2994A',
  RED_ON_PEAK: '#EB5757',
  RED_ON_PEAK_TWO: '#EB5757',
};

const COLOR_MODALITY_TOOLTIP = {
  GREEN: 'VERDE',
  YELLOW: 'AMARELO',
  RED_ON_PEAK: 'VERMELHA PT1',
  RED_ON_PEAK_TWO: 'VERMELHA PT2',
};

export default function TariffFlagsTableItem({
  tariffFlagModality: {
    tariffFlagModalityModalityFlag,
    tariffFlagModalityValidityDate,
    tariffFlagTariff: {
      tariffFlagTariffModalityFlag,
      tariffFlagTariffValidityPeriod,
      tariffFlagTariffFee,
    },
  },
  id,
}: Props): JSX.Element {
  const hendleTariffFlagTariffValidityPeriod = () => {
    const validityPeriod = new Date(tariffFlagModalityValidityDate);
    const dateNow = new Date();

    if (
      validityPeriod.getFullYear() === dateNow.getFullYear() &&
      validityPeriod.getMonth() === dateNow.getMonth()
    ) {
      return (
        <span className="text-primary">
          <MdDone className="inline-block mr-2" />
          Atual
        </span>
      );
    }

    return <span>Não vigente</span>;
  };

  const formatValidityDate = (date: Date) => {
    return new Date(date).toLocaleDateString(
      TARIFF_FLAG_VALIDITY_FORMAT_CONFIG.locale,
      TARIFF_FLAG_VALIDITY_FORMAT_CONFIG.options
    );
  };

  return (
    <TableBodyRow dataTestId={`tariff-flag-modality-row-id-${id}`}>
      <TableBodyColumn>
        {formatValidityDate(tariffFlagModalityValidityDate)}
      </TableBodyColumn>
      <TableBodyColumn>
        <div
          className="flex justify-center items-center"
          style={{
            maxWidth: '25px',
          }}
        >
          <Tooltip
            label={COLOR_MODALITY_TOOLTIP[tariffFlagModalityModalityFlag]}
            placement="bottom"
            arrowPosition="top"
          >
            <VscCircleOutline
              color={COLOR_MODALITY[tariffFlagModalityModalityFlag]}
              size={18}
            />
          </Tooltip>
        </div>
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {convertToDecimal(tariffFlagTariffFee, 6)}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {hendleTariffFlagTariffValidityPeriod()}
      </TableBodyColumn>
    </TableBodyRow>
  );
}
